.contact{
  background-color: white;
  display: flex;

  .left{
    flex: 1;
    overflow: hidden;
    object-fit: cover;
    display: flex;

    //triangle image
    .sethPicture{
      width: 500px;
      height: 500px;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      object-fit: cover;
      position: absolute;
      z-index: 2;
      margin-left: 20%;
      margin-top: 10%;
      
    }
    //circle image
    .picSeth{
      position: absolute;
      width: 350px;
      z-index: 2;
      border-radius: 50%;
      margin-left: 40%;
      margin-top: 6%;
      box-shadow: 10px 10px 5px grey;

    }
    //square image
    .sethPic{
      position: absolute;
      width: 350px;
      z-index: 1;
      margin-left: 5%;
      margin-top: 6.4%;
      box-shadow: 10px 10px 5px grey;
    }
  }
  .right{
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 30px;
    }

    form{
      width: 70%;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      input{
        width: 300px;
        height: 30px;
        font-size: 14px;
      }

      textarea{
          width: 300px;
          height: 200px;
          font-size: 14px;
      }

      button{
        width: 150px;
        height: 30px;
        color: white;
        background-color: lightslategray;
        border: none;
        border-radius: 10px;
        cursor: pointer;

      }
    }
  }
}

@media (max-width: 500px) {
  .contact {
    flex-direction: column;

    .left > .picSeth{
    width: 180px;
    margin-left: 220px;
    }

    .left > .sethPicture {
      width: 200px;
      height: 200px;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      object-fit: cover;
      position: absolute;
      z-index: 2;
      margin-left: 25%;
      margin-top: 12%;
    }

    .left > .sethPic {
      position: absolute;
      width: 150px;
      z-index: 1;
      margin-top: 40px;
      margin-left: 5%;
      // box-shadow: 10px 10px 5px grey;
    }

    #contactName {
      width: 250px;
      border: none;
      margin-bottom: 10px;
    }

    #contactEmail {
      width: 250px;
      border: none;
    }

    #contactTextArea {
      width: 260px;
      border: none;
      margin-bottom: px;
    }
  }
}