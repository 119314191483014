// @import '../../global.scss';


.topbar{
  width: 100%;
  height: 70px;
  background-color: rgb(0, 0, 0);
  color:rgb(255, 255, 255);
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;


  
  .wrapper{
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left{
      display: flex;
      align-items: center;
      

      .logo{
        font-size: 40px;
        font-weight: 700;
        text-decoration: none;
        color: inherit;
        margin-right: 40px;
      }

      .itemContainer{
        display: flex;
        align-items: center;
        margin-left: 30px;

        // @include mobile{
        //   display: none;
        // }

        .icon{
          font-size: 18px;
          font-weight: 5px;
          margin-right: 5px;
        }

        .span{
          font-size: 15px;
          font-weight: 600;

        }
      }
    }
    .right{

      .hamburger{
        height: 32px;
        width: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span{
          width: 100%;
          height: 3px;
          background-color: rgb(233, 240, 247);
          transform-origin: left;
          transition: all 2s ease;
        }
      }
    }
  }

  &.active{
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);


    .hamburger{
      span{
        &:first-child{
          background-color: rgb(0, 0, 0);
          transform: rotate(50deg);
        }
        &:nth-child(2){
          opacity: 0;
        }
        &:last-child{
          background-color: rgb(0, 0, 0);
          transform: rotate(-50deg)
        }
      }
    }
  }
}


@media (max-width: 500px) {
  .topbar {

    .wrapper > .left > .itemContainer > .topbarContact {
      display: none;
    }

  }
}