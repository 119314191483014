// @import '../../global.scss

.intro{
  background-color: white;
  display: flex;

  .left{
    flex: 0.5;
    overflow: hidden;

    .imgContainer{
      width: 600px;
      height: 600px;
     //border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      // float: right;
      margin-top: 6%;
      margin-left: 5%;
      margin-bottom: 10%;
      position: relative;
      // background-color: blue;

      
      .picSeth{
        width: 100%;
        height: auto;
        display: block;

        // align-items: center;
        // justify-content: center;
    
        // @include mobile{
        //   height: 70%;
        //   margin-bottom: 500px;
        //   margin-left: 100px;
        // }
      }

      .overlay{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height:100%;
        width: 100%;
        opacity: 0;
        border-radius: 50%;
        transition: 2s ease;
        background-color: rgb(0, 0, 0);


        /// About me section 
        .fullName, .colon{
          color:  rgb(21, 157, 206);
        }

        .curlyBracket{
          color: rgb(255, 238, 46);
        }

        .skills{
          color: rgb(127, 204, 232);
        }
        .pets{
          text-align: left;
        }
        .equal{
          color: white;
        }
        .constant{
          color: rgb(46, 134, 189)
        }

        .bracket{
          color: rgb(242, 102, 221)
        }

        .aboutMeItem {
          color: rgb(252, 161, 104)
        }

        .text{
          color: white;
          font-size: 30px;
          margin-top: 25%;
          left: 50%;
          // -webkit-transform: translate(-50%, -50%);
          // -ms-transform: translate(-50%, -50%);
          // transform: translate(-50%, -50%);
          text-align: center;
        }
      }
      
    }

    .imgContainer:hover .overlay {
      opacity: 1;
    }
  }
  .right{
    flex: 0.5;

    .wrapper{
      width: 110vh;
      height: 110vh;
     // border-radius: 45%;
      display: flex;
      align-items: center;

      img{
        height: 90%;

        // @include mobile{
        //   height: 50%;
        //   margin-left: 30%;
        // }
      }
    }
  }
}

@media (max-width: 500px) {
  .intro {
    flex-direction: column;
    align-items: center;

    .left > .imgContainer{
      align-items: flex-start;
      margin-right: 50px;
      margin-top: 32px;
      height: 300px;
      width: 300px;
    }

    .right > .wrapper {
      width: 100vw;
      height: 100vh;
    }
  

  }

}
  
