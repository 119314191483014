// .mainContain{
//   height: 100%;
//   width: 100%;
//   position: sticky;
//   top: 0%;
//   display: flex;
//   z-index: 999;
// //   background-color: lightgrey;
// }

.portfolio{
  display: flex;
  background-color: white;
  // overflow: scroll;

  .left{
    margin-top: 5%;
    height: 70vh;
    flex: 0.5;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: scroll;

    h1 {
      font-size: 50px;
    }

    ul {
      margin: 10px;
      padding: 0;
      list-style: none;
      display: flex;
    }
      li{
          font-size: 30px;
          margin-right: 50px;
          padding: 0.7;
          border-radius: 10px;
          cursor: pointer;
        }

    .item {
      width: 620px;
      height: 150px;
      border-radius: 20px;
      border: 3px solid rgb(0, 0, 0);
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      // position: relative;
      transition: all 2s ease;
      cursor: pointer;
      object-fit: cover;
    
    
    .item >h3{
      // justify-content: center;
      // align-items: center;
      // position: relative;
      // font-size: 20px;
      // color: rgb(0, 0, 0);
      // bottom: 68%;
      // text-align: center;
      // opacity: 0%;
      // z-index: 1;
    }
    img {
      width: 100%;
      object-fit: cover;
      z-index: 1;
      overflow: hidden;
    }
  }
}
  .right{
    display: flex;
    justify-content: center;
    flex: 0.5;
    display: flex;
    height: 80vh;
    img{
      width: 500px;
      height: 500px;
      position: absolute;
      margin-top: 8%;
    }
    
  }
}


@media (max-width: 500px) {
  .portfolio {
    flex-direction: column;

    .left{
      border: none;
    }
    .left > .item {
      width: 80px;
      height: 80px;
    }

    .left > .item > h3{
      display: none;
    }


    .right > img {
      width: 200px;
      height: 200px;
      position: relative;
      margin-bottom: 40px;
    }
  }

}

// .navItems{
//   display: flex;
//   justify-content: center;
//   margin-right: 0%;
//   padding: 0%;
//   list-style: none;
// }

// .items{
//   margin-right: 10px;
//   font-size: 25px;
//   margin-top: 2%;
//   cursor: pointer;
// }

// .navImage{
//   height: 45px;
//   border-radius: 50%;
// }

// .search{
//   font-size: 25px;
//   cursor: pointer;
// }

// .portfolio {
//   background-color: white;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }
//     h1 {
//       font-size: 50px;
//     }

//     ul {
//       margin: 10px;
//       padding: 0;
//       list-style: none;
//       display: flex;
//     }
//         li{
//           font-size: 30px;
//           margin-right: 50px;
//           padding: 0.7;
//           border-radius: 10px;
//           cursor: pointer;
//         }
//           // &.active{
//           //   background-color: lightslategray;
//           //   color: white;
//           // }

//     // .mainContain{
//     //   display: flex;
//     //   .containerLeft {
//     //     width: 100%;
//     //     display: flex;
//     //     flex: 6;
//     //     align-items: center;
//     //     justify-content: center;
//     //     flex-wrap: wrap;
//     //     background-color: yellow;
        
//         .item {
//           width: 220px;
//           height: 150px;
//           border-radius: 20px;
//           border: 1px solid rgb(240, 239, 239);
//           margin: 10px 20px;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           color: white;
//           position: relative;
//           transition: all 2s ease;
//           cursor: pointer;
          
          
//           h3 {
//             justify-content: center;
//             align-items: center;
//             position: absolute;
//             font-size: 20px;
//             color: white;
//             bottom: 68%;
//             opacity: 0%;
//           }
//         }
        
//           img {
//             width: 100%;
//             height: 100%;
//             object-fit: cover;
//             z-index: 1;
//           }

//         //   &:hover {
//         //     background-color: rgb(0, 0, 0);
//         //   img {
//         //     opacity: 0.2;
//         //     z-index: 0;
//         //   }
//         //   h3{
//         //     opacity: 100%;
//         //   }
//         // }
// //     .right{
// //       background-color: rgb(0, 255, 34);
// //     }
// //     .imgContainer{
// //       width: 300px;
// //       height: 300px;
// //       display: flex;
// //       justify-content: center;
// //       align-items: center;
// //       object-fit: fill;
// //       border-radius: 50%;
// //     }
// //   }
// // }
