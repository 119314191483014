// @import '../../global.scss';

.menu{
  width: 300px;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 1s ease;

  // @include mobile{
  //   width: 155px;
  // }

  &.active{
    right: 0;
  }
  
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 25px;
    font-weight: 300;
    color: rgb(0, 0, 0);
    width: 60%;

    li{

      margin-bottom: 20px;
      a{
        font-size: inherit;
        color: inherit;
        text-decoration: none;
      }

      &:hover{
        font-weight: 500;
      }
    }
  }

}

@media (max-width: 500px) {
  .menu {

    width: 180px;

  }
}